<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.services.edit-order") }}
        </div>
        <template v-slot:right>
          <button
            @click="
              submitFormState = true;
              initSubmit();
            "
            v-if="
              selectedOrderItems &&
                selectedOrderItems.length &&
                !serviceOrder.data.deleted_at
            "
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="serviceOrder">
      <li
        class="clebex-item-section-item full-right-underline"
        style="cursor: pointer;"
      >
        <div
          class="clebex-item-content-wrapper"
          @click="showInfoBox = !showInfoBox"
          style="cursor: pointer;"
        >
          <div
            class="clebex-section-input"
            style="cursor: pointer;"
            @click="showInfoBox = !showInfoBox"
          >
            <label
              class="clebex-section-input-label"
              for="orderNumber"
              style="cursor: pointer;"
              @click="showInfoBox = !showInfoBox"
              >{{ displayLabelName("services.services.order-number") }}</label
            >
            <span
              style="cursor: pointer; color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
              @click="showInfoBox = !showInfoBox"
            >
              {{ serviceOrder.data.order_id }}</span
            >
          </div>
          <span class="follow-up-icon-item" style="cursor: pointer;">
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              style="cursor: pointer; margin-right: 15px; margin-top: 5px;"
              :style="[
                !showInfoBox
                  ? 'transform: rotate(90deg)'
                  : 'transform: rotate(-90deg)'
              ]"
            />
          </span>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("services.services.resource")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.path }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.address")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.address }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="requestBy">{{
              displayLabelName("services.services.request-by")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="requestBy"
              v-model="serviceOrder.data.request_by"
              disabled="true"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="dateTime">{{
              displayLabelName("services.services.created-at")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.created_at }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="dateTime">{{
              displayLabelName("services.service-items.number-of-items")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="dateTime"
              v-model="serviceItemsLength"
              disabled="true"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox && serviceOrder.data.deleted_at"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.deleted-at")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.deleted_at }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
        style="cursor: pointer;"
      >
        <router-link
          :to="{
            name: 'r_services-edit-order-history'
          }"
          class="clebex-item-content-wrapper"
        >
          <div class="clebex-section-input" style="cursor: pointer;">
            <label class="clebex-section-input-label" style="cursor: pointer;">
              {{ displayLabelName("services.services.history") }}
            </label>
          </div>
          <span class="follow-up-icons" style="cursor: pointer;">
            <span class="follow-up-icon-item" style="cursor: pointer;">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
                style="cursor: pointer;"
              />
            </span>
          </span>
        </router-link>
      </li>
    </ul>
    <VeeForm
      :key="formKey"
      ref="editServiceOrderForm"
      @submit="submitForm"
      class="form inline-input edit-form"
      style="height: auto;"
      novalidate
    >
      <ul
        class="clebex-item-section pill"
        v-if="listOfServiceOrderItems && listOfServiceOrderItems.length"
      >
        <!-- Date from - date to -->
        <span class="delivery-period">{{
          displayLabelName("services.services.date-time")
        }}</span>
        <br /><br />
        <li
          class="clebex-item-section-item"
          style="margin-bottom: 0px; cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFromParsed) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-calendar" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateToParsed) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- Hour from - hour to -->
        <li
          class="clebex-item-section-item time"
          style="cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-top-left-radius: 0px; border-top-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="showDateTimePicker"
        >
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateTimePicker"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :rangeMarked="false"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                  :rangeMarked="false"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
      </ul>

      <!-- Selected service items -->
      <span
        class="order-level"
        style="margin-left: 15px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
        >{{ displayLabelName("services.services.selected-items") }}</span
      >
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 10px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
      >
        <li
          class="clebex-item-section-item"
          v-for="selectedOrderItem in selectedOrderItems"
          :key="selectedOrderItem.id"
        >
          <div style="display: flex; flex-direction: row;">
            <button class="clebex-item-content-wrapper">
              <span
                class="label"
                @click="showHideSelectedItem(selectedOrderItem.id)"
              >
                <span class="highlight">{{ selectedOrderItem.name }}</span>
                <span
                  v-if="
                    !showSelectedItem(selectedOrderItem.id) &&
                      getQuantity(selectedOrderItem.id)
                  "
                >
                  ( {{ getQuantity(selectedOrderItem.id) }} )</span
                >
              </span>
              <span class="follow-up-icons">
                <span
                  class="follow-up-icon-item"
                  v-if="checked(selectedOrderItem.id)"
                  @click="
                    selectShowHideOrderItem(
                      selectedOrderItem.id,
                      selectedOrderItem.has_quantity
                    );
                    selectOrderItem(selectedOrderItem);
                  "
                >
                  <icon icon="#cx-app1-checkmark"></icon>
                </span>
              </span>
            </button>
            <span
              class="follow-up-icons service-item-info"
              style="cursor: pointer;"
              @click="openServiceItemInfo(selectedOrderItem.id)"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-information"></icon>
              </span>
            </span>
          </div>
          <div
            class="order-wrapper"
            v-if="showSelectedItem(selectedOrderItem.id)"
          >
            <div
              class="clebex-item-content-wrapper clebex-item-section-item order-item"
              style="margin-top: 3px !important;"
              v-if="
                checked(selectedOrderItem.id) && selectedOrderItem.has_quantity
              "
            >
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label order-label"
                  for="${selectedOrderItem.id}quantity"
                  >{{ displayLabelName("services.services.quantity") }}</label
                >
                <div style="display: flex; flex-direction: row;">
                  <span
                    class="change-quantity"
                    @click="decreaseQuantity(selectedOrderItem.id)"
                    >-</span
                  >
                  <Field
                    :name="selectedOrderItem.id + '-quantity'"
                    as="input"
                    type="number"
                    disabled="true"
                    class="order-field"
                    style="width: 50px; text-align: center;"
                    :value="
                      listOfShowHideSelectedItems.find(
                        el => el.id == selectedOrderItem.id
                      ).quantity
                    "
                  />
                  <span
                    class="change-quantity"
                    @click="increaseQuantity(selectedOrderItem.id)"
                    >+</span
                  >
                </div>
              </div>
            </div>
            <div
              class="clebex-item-content-wrapper clebex-item-section-item order-item"
              style="margin-bottom: 10px !important"
              v-if="checked(selectedOrderItem.id)"
            >
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label order-label"
                  for="${selectedOrderItem.id}quantity"
                  >{{ displayLabelName("services.services.note") }}</label
                >
                <Field
                  :name="selectedOrderItem.id + '-note'"
                  as="input"
                  type="text"
                  class="order-field"
                  :keyup="updateNote(selectedOrderItem.id)"
                  :value="
                    listOfShowHideSelectedItems.find(
                      el => el.id == selectedOrderItem.id
                    ).note
                  "
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- Service items -->

      <span class="order-level" style="margin-top: 20px; margin-left: 15px;">{{
        displayLabelName("services.service-items.service-items")
      }}</span>
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 0px;"
        v-for="(serviceOrderType, index) in listOfServiceOrderItems"
        :key="index"
      >
        <div
          class="service-order-type"
          @click="showHideType(serviceOrderType.id)"
        >
          <span class="order-level"
            >{{ serviceOrderType.name }} ({{
              selectedOrderItems.filter(
                el => el.service_type_id == serviceOrderType.id
              ).length
            }}/{{ serviceOrderType.items.length }})</span
          ><icon
            class="order-type-icon"
            icon="#cx-hea1-arrow-left"
            style="[
              showItemType(serviceOrderType.id)
                ? 'transform: rotate(90deg)'
                : 'transform: rotate(-90deg)'
            ]"
          />
        </div>
        <div v-if="showItemType(serviceOrderType.id)" style="margin-top: 7px;">
          <li
            class="clebex-item-section-item"
            v-for="serviceOrderItem in serviceOrderType.items"
            :key="serviceOrderItem.id"
          >
            <div
              style="display: flex; flex-direction: row;"
              v-if="
                !selectedOrderItems.find(el => el.id == serviceOrderItem.id)
              "
            >
              <button
                class="clebex-item-content-wrapper"
                @click="
                  selectShowHideOrderItem(
                    serviceOrderItem.id,
                    serviceOrderItem.has_quantity
                  );
                  selectOrderItem(serviceOrderItem);
                "
              >
                <span class="label">
                  <span class="highlight">{{ serviceOrderItem.name }}</span>
                </span>
                <span class="follow-up-icons">
                  <span
                    class="follow-up-icon-item"
                    v-if="checked(serviceOrderItem.id)"
                  >
                    <icon icon="#cx-app1-checkmark"></icon>
                  </span>
                </span>
              </button>
              <span
                class="follow-up-icons service-item-info"
                style="cursor: pointer;"
                @click="openServiceItemInfo(serviceOrderItem.id)"
              >
                <span class="follow-up-icon-item">
                  <icon icon="#cx-app1-information"></icon>
                </span>
              </span>
            </div>
          </li>
        </div>
      </ul>
      <br /><br />
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { DateTime } from "luxon";
import { defineAsyncComponent } from "vue";
import { formatDate } from "@/services/helpers";

export default {
  name: "AddOrder",
  data() {
    return {
      formKey: 0,
      searchQuery: "",
      submitFormState: false,
      edit: false,
      showDateTimePicker: false,
      preselectedTab: "time-slot",
      pillTabs: ["date", "time-slot"],
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedHourFrom: this.getInitTime(),
      selectedHourTo: this.getInitTime(1),
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      listOfShowHideTypes: null,
      listOfShowHideSelectedItems: [],
      showInfoBox: false
    };
  },
  created() {
    this.setServiceOrder(null);
    this.selectOrderItem([]);
    this.getServiceOrder(this.$route.params.service_order_id);
  },
  watch: {
    routeParam() {
      this.edit = false;
      if (this.routeParam) {
        this.setServiceOrder(null);
        this.selectOrderItem([]);
        this.getServiceOrder(this.$route.params.service_order_id);
        this.showInfoBox = false;
      }
    },
    serviceOrder() {
      if (this.serviceOrder && this.serviceOrder.data) {
        this.getServiceOrderItems(this.serviceOrder.data.resource_id);
      }
    },
    serviceOrderItems() {
      this.listOfShowHideTypes = this.serviceOrderItems.data.map(item => ({
        id: item.id,
        show: this.$route.params.order_type_id == item.id ? true : false
      }));

      for (var i = 0; i < this.serviceOrderItems.data.length; i++) {
        var items = this.serviceOrderItems.data[i].items;
        for (var k = 0; k < items.length; k++) {
          for (
            var j = 0;
            j < this.serviceOrder.data.service_items.length;
            j++
          ) {
            if (items[k].id == this.serviceOrder.data.service_items[j].id) {
              this.selectOrderItem(items[k]);
              this.listOfShowHideSelectedItems.push({
                id: items[k].id,
                quantity: this.serviceOrder.data.service_items[j].quantity,
                note: this.serviceOrder.data.service_items[j].note,
                show: false
              });
            }
          }
        }
      }
    },
    serviceResource() {
      this.getResourceServices(this.serviceResource);
      if (this.serviceOrder && this.serviceOrder.data) {
        this.selectedDateFrom = DateTime.fromISO(
          this.serviceOrder.data.delivery_start
        ).toFormat("yyyy-dd-MM");
        this.selectedDateTo = DateTime.fromISO(
          this.serviceOrder.data.delivery_end
        ).toFormat("yyyy-dd-MM");
        this.selectedDateFromParsed = DateTime.fromISO(
          this.serviceOrder.data.delivery_start
        ).toFormat("yyyy-MM-dd");
        this.selectedDateToParsed = DateTime.fromISO(
          this.serviceOrder.data.delivery_end
        ).toFormat("yyyy-MM-dd");
        this.selectedHourFrom = DateTime.fromISO(
          this.serviceOrder.data.delivery_start
        ).toFormat("HH:mm");
        this.selectedHourTo = DateTime.fromISO(
          this.serviceOrder.data.delivery_end
        ).toFormat("HH:mm");
      }
    }
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("resource", ["resourceServices", "selectedResourceServices"]),
    ...mapState("service", [
      "serviceOrder",
      "serviceOrderItems",
      "selectedOrderItems"
    ]),
    routeParam() {
      return this.$route.params.service_order_id;
    },
    serviceResource() {
      if (this.serviceOrder && this.serviceOrder.data) {
        return this.serviceOrder.data.resource_id;
      }
      return null;
    },
    serviceItemsLength() {
      if (
        this.serviceOrder &&
        this.serviceOrder.data &&
        this.serviceOrder.data.service_items
      ) {
        return this.serviceOrder.data.service_items.length;
      }

      return 0;
    },
    listOfSelectedOrderItems() {
      if (this.selectedOrderItems) {
        return this.selectedOrderItems;
      }
      return null;
    },
    listOfOrderItems() {
      if (
        this.resourceServices &&
        this.resourceServices.data &&
        this.resourceServices.data.length &&
        this.serviceOrder &&
        this.serviceOrder.data &&
        this.serviceOrder.data.service_items
      ) {
        if (this.edit == false) {
          for (var i = 0; i < this.resourceServices.data.length; i++) {
            for (
              var j = 0;
              j < this.serviceOrder.data.service_items.length;
              j++
            ) {
              if (
                this.resourceServices.data[i].id ==
                this.serviceOrder.data.service_items[j].id
              ) {
                this.selectResourceService(this.resourceServices.data[i]);
              }
            }
          }
          this.setEdit(true);
        }
        return this.resourceServices.data;
      }
      return null;
    },
    listOfServiceOrderItems() {
      if (this.serviceOrderItems && this.serviceOrderItems.data) {
        return this.serviceOrderItems.data;
      }
      return null;
    },
    editedServiceItems() {
      if (this.serviceOrder && this.serviceOrder.data) {
        return this.serviceOrder.data.service_items;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("resource", [
      "getResourceServices",
      "setResourceServices",
      "selectResourceService",
      "setSelectedResourceServices"
    ]),
    ...mapActions("service", [
      "setServiceResource",
      "getServiceOrder",
      "setServiceOrder",
      "getServiceOrders",
      "getServiceOrderItems",
      "selectOrderItem"
    ]),
    increaseQuantity(id) {
      const fieldElement = document.querySelector(`[name="${id}-quantity"]`);
      fieldElement.value++;
      this.listOfShowHideSelectedItems.find(el => el.id == id).quantity =
        fieldElement.value;
      fieldElement.dispatchEvent(new Event("input"));
      fieldElement.dispatchEvent(new Event("change"));
    },
    decreaseQuantity(id) {
      const fieldElement = document.querySelector(`[name="${id}-quantity"]`);
      if (fieldElement.value > 0) {
        fieldElement.value--;
      }
      this.listOfShowHideSelectedItems.find(el => el.id == id).quantity =
        fieldElement.value;
      fieldElement.dispatchEvent(new Event("input"));
      fieldElement.dispatchEvent(new Event("change"));
    },
    getQuantity(id) {
      return this.listOfShowHideSelectedItems.find(el => el.id == id).quantity;
    },
    updateNote(id) {
      const fieldElement = document.querySelector(`[name="${id}-note"]`);
      if (fieldElement) {
        this.listOfShowHideSelectedItems.find(el => el.id == id).note =
          fieldElement.value;
      }
    },
    showItemType(id) {
      if (this.listOfShowHideTypes) {
        return this.listOfShowHideTypes.find(item => item.id == id).show;
      }
      return false;
    },
    showHideType(id) {
      var showHide = this.listOfShowHideTypes.find(item => item.id == id).show;
      this.listOfShowHideTypes.find(item => item.id == id).show = !showHide;
    },
    showHideSelectedItem(id) {
      var showHide = this.listOfShowHideSelectedItems.find(
        item => item.id == id
      ).show;

      if (showHide == false) {
        this.listOfShowHideSelectedItems.forEach(item => {
          item.show = false;
        });
      }

      this.listOfShowHideSelectedItems.find(
        item => item.id == id
      ).show = !showHide;
    },
    selectShowHideOrderItem(id, hasQuantity) {
      var item = this.listOfShowHideSelectedItems.find(el => el.id == id);
      if (item) {
        this.listOfShowHideSelectedItems = this.listOfShowHideSelectedItems.filter(
          el => el.id != id
        );
      } else {
        this.listOfShowHideSelectedItems.forEach(item => {
          item.show = false;
        });
        this.listOfShowHideSelectedItems.push({
          id: id,
          quantity: hasQuantity == 0 ? null : 1,
          note: null,
          show: true
        });
      }
    },
    showSelectedItem(id) {
      if (
        this.listOfShowHideSelectedItems &&
        this.listOfShowHideSelectedItems.find(item => item.id == id)
      ) {
        return this.listOfShowHideSelectedItems.find(item => item.id == id)
          .show;
      }
      return false;
    },
    getInitTime(offset = 0) {
      let minutes =
        (5 * Math.round(parseInt(DateTime.now().toFormat("mm")) / 5)) % 60;
      /**
       * Convert 5 to 05 (when current minute is lesst then `08`)
       * "en-US" is irrelevant, it can be undefined also
       */
      minutes = minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2
      });
      const hours = DateTime.now()
        .plus({ hours: offset })
        .toFormat("HH");

      return `${hours}:${minutes}`;
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = this.selectedDateFrom;
        this.selectedDateToParsed = this.selectedDateFromParsed;
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    dateFormat(date) {
      return formatDate(
        date,
        `${this.globalDateFormat} ${this.globalTimeFormat}`
      );
    },
    openServiceItemInfo(serviceItemId) {
      this.$router.push({
        name: "r_services-edit-order-item-info",
        params: {
          order_item_id: serviceItemId
        }
      });
    },
    setEdit(value) {
      this.edit = value;
    },
    checked(id) {
      return (
        this.selectedOrderItems &&
        this.selectedOrderItems.length &&
        !!this.selectedOrderItems.find(item => item.id === id)
      );
    },
    formValues() {
      let response = [];

      for (var i = 0; i < this.selectedOrderItems.length; i++) {
        let object = {
          quantity: null,
          note: "",
          service_status_id: 1,
          service_status: "ORDER"
        };

        var item = this.editedServiceItems.find(
          el => el.id == this.selectedOrderItems[i].id
        );

        if (item) {
          object.service_status_id = item.service_status_id;
          object.service_status = item.service_status;
        }

        object.id = this.selectedOrderItems[i].id;
        object.quantity = this.listOfShowHideSelectedItems.find(
          el => el.id == this.selectedOrderItems[i].id
        ).quantity;
        object.note = this.listOfShowHideSelectedItems.find(
          el => el.id == this.selectedOrderItems[i].id
        ).note;
        response.push(object);
      }
      return response;
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      if (values && this.submitFormState) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.submitFormState = false;

      const dateTimeFrom = `${DateTime.fromISO(
        this.selectedDateFromParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.selectedDateToParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;

      values = {
        resource_id: this.serviceResource,
        service_status_id: 1,
        service_items: this.formValues(),
        delivery_start: dateTimeFrom,
        delivery_end: dateTimeTo
      };
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.serviceOrders}/${this.$route.params.service_order_id}`,
          values
        )
        .then(() => {
          this.getServiceOrders();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    )
  }
};
</script>
<style>
.order-wrapper {
  border: 1px solid lightgray !important;
  border-top: 0px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 3px;
}
.order-item {
  border-radius: 0px !important;
  background: transparent !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}
.order-label {
  padding-left: 0px !important;
}
.order-field {
  background: white !important;
  padding: 5px !important;
  height: 2rem !important;
}
.service-item-info {
  background-color: white;
  border-radius: 10px;
  margin-left: 5px;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}
.delivery-period {
  color: #999999;
  font-size: 0.9375rem;
  line-height: 1.26667em;
  letter-spacing: -0.36px;
  margin-left: 1rem;
  margin-bottom: 5px !important;
}
.order-type-icon {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  transform: rotate(-90deg);
  margin-right: 15px;
}
.order-level {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 0px !important;
  margin-left: 7px;
}
.service-order-type {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 0.8rem;
  background-color: #fff;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  align-items: center;
}
.change-quantity {
  border: 1px solid lightgray;
  padding-top: 1px;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
